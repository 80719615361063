/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'

// Elements
import TitleDefault from 'components/elements/TitleDefault'
import ParseContent from 'components/shared/ParseContent'

// Components
import SEO from 'components/shared/SEO'
import Layout from 'components/layout/Layout'
import GravityForm from 'components/shared/GravityForm'
import Markten from 'components/shared/Markten'

// Icons
import Location from 'img/location.inline.svg'
import MailIcon from 'img/iconmail.inline.svg'
import Phone from 'img/phone.inline.svg'
import Facebook from 'img/facebook.inline.svg'
import Instagram from 'img/instagram.inline.svg'

import formBG from 'img/form-bg.png'

const Content = styled(ParseContent)`
  & h4 {
    font-size: ${(props) => props.theme.font.size.m};
    line-height: ${(props) => props.theme.font.size.l};
    margin-bottom: 0;
  }

  & p {
    margin-bottom: 0;
  }
`

const PhoneIcon = styled(Phone)`
  width: 21px;
`

const FormColumn = styled.div`
  background: url(${formBG});
`

const WhiteSection = styled.section`
  position: relative;
  z-index: 2;

  & .bg-white {
    height: 50px;
  }
`

const IframeSection = styled.section`
  position: relative;
  z-index: 1;
  margin-top: -98px;

  & iframe {
    border: 0;
    pointer-events: none;
  }
`

const PageTemplate = ({
  data: {
    page: { path, yoast_meta: yoast, acf },
  },
}) => {
  return (
    <Layout>
      <SEO yoast={yoast} path={path} article />

      <section>
        <div className="container py-lg-3">
          <div className="row py-5 align-items-center">
            <div className="col-lg-5 mb-5 mb-lg-0">
              <TitleDefault>{acf.info.title}</TitleDefault>
              <ParseContent
                className="pr-5 mb-4 pb-1"
                content={acf.info.description}
              />

              <div className="d-flex align-items-start mb-4">
                <Location />
                <Content content={acf.info.address} className="pl-2" />
              </div>

              <div className="d-flex align-items-start mb-4">
                <MailIcon />
                <Content content={acf.info.email} className="pl-2" />
              </div>

              <div className="d-flex align-items-start mb-5">
                <PhoneIcon />
                <Content content={acf.info.phone} className="pl-2" />
              </div>

              <div>
                <Content
                  content={acf.info.socialMedia.title}
                  className="mb-2"
                />
                <div>
                  <a
                    href={acf.info.socialMedia.instagram}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Instagram />
                  </a>
                  <a
                    href={acf.info.socialMedia.facebook}
                    target="_blank"
                    className="ml-2"
                    rel="noreferrer"
                  >
                    <Facebook />
                  </a>
                </div>
              </div>
            </div>
            <FormColumn className="col-lg-7 d-flex align-items-center justify-content-center p-4 p-lg-5 mx-3 mx-lg-0">
              <GravityForm id={3} className="w-100" />
            </FormColumn>
          </div>
        </div>
      </section>

      <section>
        <div className="container">
          <div className="row pt-lg-5">
            <div className="col-12 mb-4">
              <TitleDefault>Waar kunt u ons vinden?</TitleDefault>
            </div>
            <Markten columns />
          </div>
        </div>
      </section>

      <WhiteSection>
        <div className="col-12 bg-white" />
      </WhiteSection>

      <IframeSection className="py-5">
        <iframe
          src="https://www.google.com/maps/d/u/0/embed?mid=1_nkOHQEUsJaZfO2SySz3lds05MwESi4N"
          width="100%"
          height="480"
          frameBorder="0"
          title="Google Maps"
          allowFullScreen=""
          aria-hidden="false"
          tabIndex="0"
        />
      </IframeSection>
    </Layout>
  )
}

export const pageQuery = graphql`
  query ($wordpress_id: Int) {
    page: wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      title
      path

      acf {
        info {
          title
          description
          address
          email
          phone
          socialMedia: social_media {
            title
            instagram
            facebook
          }
        }

        maps {
          image {
            localFile {
              publicURL
            }
          }
        }
      }

      yoast_meta {
        name
        content
        property
      }
    }
  }
`

export default PageTemplate
